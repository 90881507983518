export const CATEGORY_BLOGS = "blogs"
export const CATEGORY_NEWS = "news"
export const CATEGORY_RESOURCES = "resources"

export const DOCS_TYPE_MENU = "menu"
export const DOCS_TYPE_CONTENT = "content"
export const DOCS_TYPE_LINK = "link"

export const RES_DATASHEET = "datasheet"
export const RES_SURVEY = "survey"
export const RES_PAPER = "paper"
export const RES_BLOG = "blog"
export const RES_ANALYST = "analyst"

export const ResourceContentMap = {
  [RES_DATASHEET]: [RES_DATASHEET],
  [RES_PAPER]: [RES_PAPER, RES_SURVEY, RES_ANALYST],
}

// Videos Sections
export const VID_EVENTS = "events"
export const VID_INTERVIEWS = "interviews"
export const VID_PROD_OVERVIEWS = "product-overviews"
export const VID_PROD_DEMOS = "product-demos"
export const VID_TECHNOLOGY = "technology"

export const VideosContentMap = {
  [VID_EVENTS]: [VID_EVENTS],
  [VID_INTERVIEWS]: [VID_INTERVIEWS],
  [VID_PROD_OVERVIEWS]: [VID_PROD_OVERVIEWS],
  [VID_PROD_DEMOS]: [VID_PROD_DEMOS],
  [VID_TECHNOLOGY]: [VID_TECHNOLOGY],
}

export const FULL_NAME_MIN_LEN = 2
export const FULL_NAME_MAX_LEN = 100

// Query Params Keys
export const PARAM_QUERY = "query"

// Local Storage Keys
export const LS_OPEN_HISTORY = "volterra:page_open_history"
export const LS_CAREER_PREFIX = "volterra:jobs:"

export const ALLOWED_FILE_FORMATS = ["doc", "docx", "pdf"]

// VoltStack Capabilities constants.
export const VS_BLOCK_DAM = "distributed-application-management"
export const VS_BLOCK_CDV = "continuous-delivery-verification"
export const VS_BLOCK_AIS = "app-identity-and-secrets"
export const VS_BLOCK_CS = "container-security"
export const VS_BLOCK_DIM = "distributed-infrastructure-management"
export const VS_BLOCK_OPTIMIZED_OS = "optimized-os"
export const VS_BLOCK_CLUSTERING = "clustering"
export const VS_BLOCK_DS = "distributed-storage"
export const VS_BLOCK_MK = "managed-kubernetes"
export const VS_BLOCK_OBSERVABILITY = "observability"
export const VS_BLOCK_MULTI_TENANCY = "multi-tenancy"

// VoltMesh Capabilities constants.
export const VM_BLOCK_AG = "api-gateway"
export const VM_BLOCK_AS = "app-security"
export const VM_BLOCK_LB = "load-balancing"
export const VM_BLOCK_SM = "service-mesh"
export const VM_BLOCK_SN = "secure-networking"
export const VM_BLOCK_SB = "secure-backbone"
export const VM_BLOCK_PROG = "programmability"
export const VM_BLOCK_OBSERVABILITY = "observability"
export const VM_BLOCK_MULTI_TENANCY = "multi-tenancy"

// Header Menu Constants
export const MENU_PRODUCTS = "products"
export const MENU_SOLUTIONS = "solutions"
export const MENU_RESOURCES = "resources"
export const MENU_PRICING = "pricing"
export const MENU_COMPANY = "company"
export const MENU_SUPPORT = "support"
export const MENU_MOBILE = "mobile"

// Terms constants
export const TERMS_CHANGES_TO_SITE = "changes_to_the_site"
export const TERMS_ACCESS_AND_USE = "access_and_use"
export const TERMS_PROHIBITED_USE = "prohibited_use"
export const TERMS_FEEDBACK = "feedback"
export const TERMS_TERMINATION = "termination"
export const TERMS_THIRD_PARTY = "third_party"
export const TERMS_AVAILABILITY = "availability"
export const TERMS_PRIVACY = "privacy_and_personal_information"
export const TERMS_DISCLAIMER = "disclaimer"
export const TERMS_LIMITATION = "limitation"
export const TERMS_DISCLAIMERS = "application_of_disclaimers"
export const TERMS_INDEMNIFICATION = "indemnification"
export const TERMS_GOVERNING_LAW = "governing_law"
export const TERMS_GENERAL = "general"
export const TERMS_QUESTIONS = "questions"

// Privacy section Constants
export const PRIVACY_INTRO = "introduction"
export const PRIVACY_CHANGES = "changes_to_policy"
export const PRIVACY_INFO_COLLECT = "info_we_collect"
export const PRIVACY_USE_INFO = "use_info"
export const PRIVACY_SHARE_INFO = "share_info"
export const PRIVACY_THIRD_PARTY = "third_party_sp"
export const PRIVACY_BUSINESS_TRANSFERS = "business_transfers"
export const PRIVACY_AFFILIATES = "affiliates"
export const PRIVACY_MARCOM = "marketing_comm"
export const PRIVACY_MANAGE = "manage_prefs"
export const PRIVACY_SENSITIVE = "sensitive_data"
export const PRIVACY_CHILDREN_PRIVACY = "children_privacy"
export const PRIVACY_PAYMENT_PROCESSING = "payment_processing"
export const PRIVACY_LINKS = "third_party_links"
export const PRIVACY_DATA_RETENTION = "data_retention"
export const PRIVACY_DATA_SECURITY = "data_security"
export const PRIVACY_INT_TRANSFERS = "int_transfers"
export const PRIVACY_ADDITIONAL = "additional_legal_rights"
export const PRIVACY_CONTACT = "contact_us"
export const PRIVACY_LEGAL = "legal_obligations"

// Cookie Policy section Constants
export const COOKIE_POLICY_EXPLAINED = "cookies_explained"
export const COOKIE_POLICY_TYPES = "cookie_types"
export const COOKIE_POLICY_PREFERENCES = "manage_prefs"
export const COOKIE_POLICY_MODIFICATIONS = "policy_modifications"
export const COOKIE_POLICY_CONTACT = "contact_us"

// f5 Careers page URL
export const CAREERS_PAGE_URL =
  "https://f5.recsolu.com/job_boards/USARc2y-c5XCyiKQlSxsbQ"

// f5 Privacy Policy page URL
export const PRIVACY_POLICY_PAGE_URL =
  "https://www.f5.com/company/policies/privacy-notice"

// List of authors
export const AUTHORS_SLUG = "/author/"
export const AUTHORS = [
  "Jakub Pavlík",
  "Manish Mehta",
  "Nico Cartron",
  "Ankur Singla",
  "Nuno Ferreira",
  "Harshad Nakil",
  "Devesh Mittal",
  "Pranav Dharwadkar",
  "Marco Rodrigues",
  "Alex Cohen",
  "Filip Pytloun",
  "Mark Weiner",
  "Benjamin Schilz",
  "Chris Tozzi",
  "Scott Fitzpatrick",
]
