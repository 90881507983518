import React, { useEffect } from "react"
import SEO from "../../components/seo"
import { CAREERS_PAGE_URL } from "../../utils/constants"
import { graphql, useStaticQuery } from "gatsby"

const CareersPage = () => {
  useEffect(() => {
    window.location.href = `${CAREERS_PAGE_URL}`
  }, [])

  const queryResult = useStaticQuery(graphql`
    {
      seo: markdownRemark(
        fields: {
          relativePath: { eq: "company__careers" }
          category: { eq: "seo" }
        }
      ) {
        frontmatter {
          metaTitle
          metaDescription
          metaKeywordList
          metaImageName
        }
      }
    }
  `)

  const {
    seo: {
      frontmatter: {
        metaTitle,
        metaDescription,
        metaKeywordList,
        metaImageName,
      },
    },
  } = queryResult

  return (
    <SEO
      title={metaTitle}
      pageUrl={CAREERS_PAGE_URL}
      external={true}
      addCanonical={true}
      description={metaDescription}
      keywords={metaKeywordList}
      imageName={
        !!metaImageName ? `/previews/${metaImageName}` : undefined
      }></SEO>
  )
}

export default CareersPage
