export const ENV_ENVIRONMENT = process.env.NODE_ENV
export const ENV_SITE_URL = process.env.FLATRENDER_SITE_URL
export const ENV_API_BASE_URL = process.env.FLATRENDER_SITE_API_BASE_URL
export const ENV_CONSOLE_BASE_URL = process.env.CONSOLE_BASE_URL
export const ENV_DOCS_BASE_URL = process.env.DOCS_BASE_URL
export const ENV_API_DOCS_BASE_URL = process.env.API_DOCS_BASE_URL
export const ENV_DEV_PORTAL_BASE_URL = process.env.DEV_PORTAL_BASE_URL
export const ENV_GOOGLE_ANALYTICS_KEY = process.env.GOOGLE_ANALYTICS_KEY
export const ENV_HUBSPOT_KEY = process.env.HUBSPOT_KEY
export const ENV_RECAPTCHA_PUBLIC_KEY = process.env.RECAPTCHA_PUBLIC_KEY
